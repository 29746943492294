@use "../global" as g;

.el_btn1 {
  display: flex;
  align-items: center;

  @include g.mq-down(lg) {
    justify-content: space-between;
  }

  .txt {
    display: inline-block;
    margin-right: 13px;
  }

  .deco {
    display: inline-block;
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 50%;
    position: relative;

    @include g.mq-down(lg) {
      width: 23px;
      height: 23px;
    }

    &::after {
      content: "";
      display: block;
      width: 4px;
      height: 4px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: g.$orange;
      border-radius: 50%;
      transition: width .2s, height .2s;
    }

    &::before {
      content: "";
      opacity: 0;
      display: block;
      width: 4px;
      height: 4px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      left: calc(50% - 2px);
      top: calc(50% - 2px);
      z-index: 2;
      transition: opacity .2s;
    }
  }

  &:hover {
    .deco {
      &::before {
        opacity: 1;
      }

      &::after {
        width: 23px;
        height: 23px;
      }
    }
  }
}

.el_extbtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;

  .icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-image: url(../images/common/icon_externallink-01.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.el_accbtn {
  display: flex;
  align-items: center;

  @include g.mq-down(lg) {
    justify-content: space-between;
  }

  .txt {
    display: inline-block;
    margin-right: 13px;
  }

  .deco {
    display: inline-block;
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 50%;
    position: relative;
    background-image: url(../images/common/icon_acc-01.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: transform .2s;

    @include g.mq-down(lg) {
      width: 23px;
      height: 23px;
    }
  }

  &.is_active {
    .deco {
      transform: rotate(-180deg);
    }
  }
}

.menu-btn {
  width: 80px;
  height: 80px;
  padding: 22px 25.5px 24px;

  .menu-trigger,
  .menu-trigger span {
    display: inline-block;
    box-sizing: border-box;
    transition: all .2s;
  }

  .menu-trigger {
    width: 100%;
    height: 20px;
    position: relative;
  }

  .menu-trigger span {
    background-color: #fff;
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
  }

  //.menu-trigger.active span {
  //    background-color: #FFFFFF;
  //}
  .menu-trigger span:nth-of-type(1) {
    top: 0;
    transform: translateY(0) rotate(0);
  }

  .menu-trigger span:nth-of-type(2) {
    top: 9px;
  }

  .menu-trigger span:nth-of-type(3) {
    bottom: 0;
    transform: translateY(0) rotate(0);
  }

  /* Animation*/
  .menu-trigger.active span:nth-of-type(1) {
    top: 50%;
    transform: rotate(45deg);
  }

  .menu-trigger.active span:nth-of-type(2) {
    transform: scaleX(0);
  }

  .menu-trigger.active span:nth-of-type(3) {
    top: 50%;
    transform: rotate(-45deg);
  }
}
