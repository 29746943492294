@use "../global" as g;

footer {
  .inquiry-sec {
    padding: 10px 0;
    .ft_logo{
      max-width: 450px;
      width: 50%;
      margin: 40px auto;
    }

  }

}
