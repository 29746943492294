@use "../global" as g;

main {
  margin-top: 70px;

  @include g.mq-down(lg) {
    margin-top: 70px;
  }

}

.ly_w1100 {
  width: calc(100% - 40px);
  max-width: 1100px;
  margin: 0 auto;
}

.ly_w1240 {
  width: calc(100% - 40px);
  max-width: 1240px;
  margin: 0 auto;
}
