@use "../global" as g;



.p-top{
    width: 100%;

    

    .mv{
        width: 100%;
        height: 0;
        padding-bottom: 155%;
        background: url(/assets/images/top/mv_bg.jpg) repeat-y center top;
        background-size: 100%;
        position: relative;
        .copy{
            max-width: 33px;
            width: 5%;
            position: absolute;
            right: 5%;
            top: 30px;
            opacity: 0;
           animation-delay: 2.5s;
        }
        .top_title{
            max-width: 480px;
            width: 63%;
            position: relative;
            margin: 0 auto;
            top: 50px;
        }
        .label{
            max-width: 63px;
            width: 9%;
            position: absolute;
            left: 5%;
            bottom: 8%;
            opacity: 0;
            animation-delay: 3s;
        }
        .momo{
            position: absolute;
            opacity: 0;
        }
        .momo1{
            max-width: 43px;
            width: 6%;
            top: 5%;
            right: 25%
        }
        .momo2{
            max-width: 51px;
            width: 7%;
            top: 10%;
            left: 20%
        }
        .ha1{
           max-width: 46px;
           width: 6%;
           right: 22%;
           top: 18%;
        }
        .momo3{
            max-width: 81px;
            width: 12%;
            top: 28%;
            left: 4%
        }
        .momo4{
            max-width: 51px;
            width: 7%;
            top: 34%;
            right: 26%
        }
        .ha2{
            max-width: 31px;
            width: 4%;
            top: 45%;
            left: 20%
        }
        .momo5{
            max-width: 62px;
            width: 8%;
            top: 55%;
            left: 23%
        }
        .momo6{
            max-width: 119px;
            width: 15%;
            right: 17%;
            top: 65%;
        }
        .momo7{
            max-width: 73px;
            width: 9%;
            bottom: 7%;
            left: 18%
        }
        .ha3{
            width: 55px;
            top: 78%;
            right: 30%
        }

        .fadeUp {
animation-name:fadeUpAnime;
animation-duration:1s;
animation-fill-mode:forwards;
opacity: 0;
}

@keyframes fadeUpAnime{
  from {
    opacity: 0;
	transform: translateY(10px);
  }

  to {
    opacity: 1;
	transform: translateY(0);
  }
}

.topView {
animation-name:topViewAnime;
animation-duration:1.5s;
animation-fill-mode:forwards;

opacity: 0;

}

@keyframes topViewAnime{
  from {
    opacity: 0;
	transform: translateY(100px);
  }

  to {
    opacity: 1;
	transform: translateY(0);
  }
}


    }

    .articleArea{
        position: relative;
        &::before{
            content: '';
position: absolute;
width: 100%;
height: 300px;
top: 0;
bottom: 0;
left: 0;
right: 0;
background-color: #57b86f;
transform: skewY(-18deg);
        }
        
        .new_article{
            width: 100%;
            position: relative;
            z-index: 1;
            &::before{
                content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff4df;
    transform: skewY(12deg);
    z-index: -1;
            }
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 300px;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: #57b86f;
                transform: skewY(-18deg);
                z-index: -2;
            }

        }
        
        .sec_ttl{
            font-size: 2.8rem;
            color: g.$pink-light;
            font-weight: 600;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 60px 0 30px;
            margin-bottom: 30px;
            position: relative;
            .slash{
                width: 33px;
                display: inline-block;
                margin: auto 30px;

            }
            &::after{
                content: "";
                display: block;
                width: 85px;
                height: 9px;
                background: url(/assets/images/common/dot_p.png) no-repeat;
                background-size: 100%;
                position: absolute;
                bottom: 0px;
                
            }
            @include g.mq-down(md) {
                font-size: 4.5vw;
            }
        }

        .article_list{
            width: 90%;
            margin: 0 auto 50px;
            padding-bottom: 50px;
            ul{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                li{
                    width: 48%;
                    margin-bottom: 40px;
                    .img{
                        position: relative;
                        width: calc(100% - 5px );
                        box-sizing: border-box;
                        
                        z-index: 1;
                        
                        &::after{
                            content: "";
                            display: block;
                            width: 100%;
                            height: 100%;
                            background-color: #ffd6d3;
                            border-radius: 10px;
                            position: absolute;
                            right: -5px;
                            bottom: -5px;
                            z-index: -1;
                        }

                        figure{
                            width: 100%;
                            border: solid 1px g.$black;
                        border-radius: 10px;
                            overflow: hidden;

                            img{
                                width: 100%;
                                object-fit: cover;

                            }
                        }

                        .rank{
                            display: flex;
                            width: 36px;
                            height: 36px;
                            background-color: #fff;
                            border-radius: 50%;
                            color: g.$pink;
                            font-weight: 600;
                            position: absolute;
                            top: 10px;
                            left: 10px;
                            font-size: 1.4rem;
                            text-align: center;
                            align-items: center;
                            justify-content: center;
                            @include g.mq-down(md) {
                                font-size: 2.5vw;
                            }
                        }

                    }
                    .caption{
                        .dateArea{
                            display: flex;
                            justify-content: space-between;
                            padding: 15px 0 10px;
                            margin-bottom: 10px;
                            border-bottom: dotted 2px #57b86f;
                            font-size: 1.4rem;
                            font-weight: 600;
                            @include g.mq-down(md) {
                                font-size: 2.5vw;
                            }
                            .date{
                                color: g.$pink-light;

                            }
                            .category{
                                color: g.$pink-light;
                                border: solid 1px g.$pink-light;
                                border-radius: 20px;
                                padding: 0 15px;
                            }
                        }
                        .ttlArea{
                            font-size: 2rem;
                            @include g.mq-down(md) {
                                font-size: 3.5vw;
                            }
                        }
                    }
                }
            }
        }
    

    }

    .lineArea{
        width: 100%;
        background-color: #00c300;
        color: #fff;
        .inner{
            width: 90%;
            margin: 0 auto;
            padding: 60px 0;
            text-align: center;
            h3{
                font-size: 2.2rem;
                line-height: 1.75;
                font-weight: 600;
                position: relative;
                &::before{
                    content: "";
                    display: block;
                    width: 40px;
                    height: 58px;
                    background: url(/assets/images/common/sp_w_l.png) no-repeat;
                    background-size: 100%;
                    position: absolute;
                    left: 5%;
                    bottom: 0;
                    
                }
                &::after{
                    content: "";
                    display: block;
                    width: 40px;
                    height: 58px;
                    background: url(/assets/images/common/sp_w_r.png) no-repeat;
                    background-size: 100%;
                    position: absolute;
                    right: 5%;
                    bottom: 0;
                    
                }

            }
            .more_btn{
                max-width: 560px;
                width: 70%;
                margin: 30px auto 0;

                a{
                    display: block;
                    text-align: center;
                    position: relative;
                    width: 100%;
                    padding: 20px 0;
                    border-radius: 20px;
                    background-color: #fff;
                    p{
                        font-size: 2rem;
                        color: #00c300;
                        font-weight: 600;
                    }
                    .plus{
                        width: 17px;
                        height: auto;
                        position: absolute;
                        right: 20px;
                        top: calc(50% - 17px);
                    }

                }

            }
        }
    }
    .btmArea{
        margin: 30px auto;
        .comment{
            width: 90%;
            margin: 0 auto;
            font-size: 1.6rem;
        }
    }

}



