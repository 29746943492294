@use "../setting" as s;

// アンダーラインアニメーション
@mixin hover-underline($type: fade, $dir: null, $weight: 2px, $color: #000) {
  @if $dir==null {
    @if $type==fade {
      $dir: "top";
    }

    @else if $type==slide {
      $dir: "center";
    }
  }

  position: relative;
  display: inline-block;
  text-decoration: none;

  &::after {
    position: absolute;
    left: 0;
    content: "";
    width: 100%;
    height: $weight;
    background: $color;

    @if $type==fade {
      transition: 0.3s;
      opacity: 0;
      visibility: hidden;

      @if $dir==bottom {
        bottom: $weight * -4;
      }

      @else {
        bottom: $weight;
      }
    }

    @else if $type==slide {
      bottom: $weight * -2;
      transform: scale(0, 1);
      transition: transform 0.3s;

      @if $dir==left-right or $dir==right-in-left {
        transform-origin: left top;
      }

      @else if $dir==right-left or $dir==left-in-right {
        transform-origin: right top;
      }

      @else {
        transform-origin: center top;
      }
    }
  }

  &:hover::after {
    @if $type==fade {
      bottom: $weight * -2;
      opacity: 1;
      visibility: visible;
    }

    @else if $type==slide {
      @if $dir==left-in-right {
        transform-origin: left top;
      }

      @else if $dir==right-in-left {
        transform-origin: right top;
      }

      transform: scale(1, 1);
    }
  }
}

//使い方
// Fade Top
//.example01 a {
//  @include hover-underline('fade', 'top');
//}
// Fade Bottom
//.example02 a {
//  @include hover-underline('fade', 'bottom');
//}
// Left to Right
//.example03 a {
//  @include hover-underline('slide', 'left-right');
//}
// Right to Left
//.example04 a {
//  @include hover-underline('slide', 'right-left');
//}
// Left in Right
//.example05 a {
//  @include hover-underline('slide', 'left-in-right');
//}
// Right in Left
//.example06 a {
//  @include hover-underline('slide', 'right-in-left');
//}
// Center
//.example07 a {
//  @include hover-underline('slide', 'center');
//}
//画像リンク
@mixin image-link() {
  @include mq-up(md) {
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }
}

//画像リンク
@mixin text-link() {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

@mixin image-link_zoom() {
  figure {
    overflow: hidden;

    img {
      transition: transform .2s;
    }

    &:hover {
      img {
        transform: scale(1.1, 1.1);
      }
    }
  }
}

//画像フィット
@mixin imgFit() {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

//YOUTUBE埋め込み
@mixin youtube() {
  width: 100%;
  padding-top: 56.25%;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

@mixin extendSlideStyle() {
  margin-bottom: 43px !important;

  .slick-dots {
    text-align: left;
    bottom: -32px;
  }

  .slick-prev {
    left: auto;
    right: 60px;
    top: calc(100% + 30px);

    @include mq-down(lg) {
      top: calc(100% + 20px);
    }
  }

  .slick-next {
    left: auto;
    right: 20px;
    top: calc(100% + 30px);

    @include mq-down(lg) {
      top: calc(100% + 20px);
    }
  }
}
