@use "../global" as g;



.p-category{
    width: 100%;
    


    .articleArea{
        position: relative;
        background-color: #fff;
        &::before{
            content: "";
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: 34.6%;
            background: url(/assets/images/category/cate_top.png) no-repeat center top;
            background-size: cover;
            position: absolute;
            top: 0;
        }
        
        .cate_article{
            width: 100%;
            position: relative;
            z-index: 1;
            


        
        
        .sec_ttl{
            font-size: 2.8rem;
            color: g.$pink-light;
            font-weight: 600;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 60px 0 30px;
            margin-bottom: 30px;
            position: relative;
            .slash{
                width: 33px;
                display: inline-block;
                margin: auto 30px;

            }
            &::after{
                content: "";
                display: block;
                width: 85px;
                height: 9px;
                background: url(/assets/images/common/dot_p.png) no-repeat;
                background-size: 100%;
                position: absolute;
                bottom: 0px;
                
            }
            @include g.mq-down(md) {
                font-size: 4.5vw;
            }
        }

        .article_list{
            width: 90%;
            margin: 0 auto 50px;
            padding-bottom: 50px;
            ul{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                li{
                    width: 48%;
                    margin-bottom: 40px;
                    .img{
                        position: relative;
                        width: calc(100% - 5px );
                        box-sizing: border-box;
                        
                        z-index: 1;
                        
                        &::after{
                            content: "";
                            display: block;
                            width: 100%;
                            height: 100%;
                            background-color: #ffd6d3;
                            border-radius: 10px;
                            position: absolute;
                            right: -5px;
                            bottom: -5px;
                            z-index: -1;
                        }

                        figure{
                            width: 100%;
                            border: solid 1px g.$black;
                        border-radius: 10px;
                            overflow: hidden;

                            img{
                                width: 100%;
                                object-fit: cover;

                            }
                        }

                        .rank{
                            display: flex;
                            width: 36px;
                            height: 36px;
                            background-color: #fff;
                            border-radius: 50%;
                            color: g.$pink;
                            font-weight: 600;
                            position: absolute;
                            top: 10px;
                            left: 10px;
                            font-size: 1.4rem;
                            text-align: center;
                            align-items: center;
                            justify-content: center;
                            @include g.mq-down(md) {
                                font-size: 2.5vw;
                            }
                        }

                    }
                    .caption{
                        .dateArea{
                            display: flex;
                            justify-content: space-between;
                            padding: 15px 0 10px;
                            margin-bottom: 10px;
                            border-bottom: dotted 2px #57b86f;
                            font-size: 1.4rem;
                            font-weight: 600;
                            @include g.mq-down(md) {
                                font-size: 2.5vw;
                            }
                            .date{
                                color: g.$pink-light;

                            }
                            .category{
                                color: g.$pink-light;
                                border: solid 1px g.$pink-light;
                                border-radius: 20px;
                                padding: 0 15px;
                            }
                        }
                        .ttlArea{
                            font-size: 2rem;
                            @include g.mq-down(md) {
                                font-size: 3.5vw;
                            }
                        }
                    }
                }
            }
        }

        .more_btn{
                max-width: 342px;
                width: 60%;
                margin: 30px auto 0;

                a{
                    display: block;
                    text-align: center;
                    position: relative;
                    width: 100%;
                    padding: 20px 0;
                    border-radius: 40px;
                    background-color: #57b86f;
                    p{
                        font-size: 2rem;
                        color: #fff;
                        font-weight: 600;
                    }
                    .plus{
                        width: 17px;
                        height: auto;
                        position: absolute;
                        right: 20px;
                        top: calc(50% - 15px);
                    }

                }

            }

        }

    }

    .lineArea{
        width: 100%;
        background-color: #00c300;
        color: #fff;
        .inner{
            width: 90%;
            margin: 0 auto;
            padding: 60px 0;
            text-align: center;
            h3{
                font-size: 2.2rem;
                line-height: 1.75;
                font-weight: 600;
                position: relative;
                &::before{
                    content: "";
                    display: block;
                    width: 40px;
                    height: 58px;
                    background: url(/assets/images/common/sp_w_l.png) no-repeat;
                    background-size: 100%;
                    position: absolute;
                    left: 5%;
                    bottom: 0;
                    
                }
                &::after{
                    content: "";
                    display: block;
                    width: 40px;
                    height: 58px;
                    background: url(/assets/images/common/sp_w_r.png) no-repeat;
                    background-size: 100%;
                    position: absolute;
                    right: 5%;
                    bottom: 0;
                    
                }

            }
            .more_btn{
                max-width: 560px;
                width: 70%;
                margin: 30px auto 0;

                a{
                    display: block;
                    text-align: center;
                    position: relative;
                    width: 100%;
                    padding: 20px 0;
                    border-radius: 20px;
                    background-color: #fff;
                    p{
                        font-size: 2rem;
                        color: #00c300;
                        font-weight: 600;
                    }
                    .plus{
                        width: 17px;
                        height: auto;
                        position: absolute;
                        right: 20px;
                        top: calc(50% - 17px);
                    }

                }

            }
        }
    }
    .btmArea{
        margin: 30px auto;
        .comment{
            width: 90%;
            margin: 0 auto;
            font-size: 1.6rem;
        }
    }

}