@use "../global" as g;

#bg_pc{
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(/assets/images/common/bg_pc.png) no-repeat center top;
  background-size: cover;
  z-index: 1;
  @include g.mq-down(md) {
    display: none;
  }

  .pc_logo{
    width: 220px;
    position: absolute;
    left: 20px;
    top: 20px;
    @include g.mq-down(lg) {
      display: none;
    }
  }

  .pc_sns{
    position: absolute;
    right: 15px;
    top: 20px;
    ul{
      display: flex;
      li{
        width: 34px;
        height: 34px;
        margin: auto 10px;
        a{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
    @include g.mq-down(lg) {
      display: none;
    }
  }

  .pc_nav{
    position: absolute;
    left: calc((100% - 540px) / 6);
    top: 35%;
    ul{
      li{
        margin-bottom: 30px;
        a{
          display: flex;
          align-items: center;
          .momo_icon{
            display: inline-block;
            width: 36px;
            height: auto;
            margin-right: 15px;
          }
          p{
            font-size: 2rem;
            color: #ff778c;
            font-weight: 600;
          }
        }
      }
    }
    @include g.mq-down(xl) {
      display: none;
    }
  }
  .scrollArea{
    position:absolute;
	  top: 35%;
	  left:75%;
    height: 200px;
    span{
      position: absolute;
	right:-30px;
	top: 30%;
	color: #ff778c;
	font-size: 1em;
  font-weight: 600;
	letter-spacing: 0.05em;
    writing-mode: vertical-rl;
    }
    .arrowdown{
      width: 3px;
  height: 150px;
  margin: 50px auto 0;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  &::before{
    content: "";
  position: absolute;
  top:-150px;
  right:0;
width:3px;
height:150px;
background:#ff778c;
animation: arrow 2.5s ease 0s infinite normal;
  }
  @keyframes arrow {
    0% {
      transform: translate3d(-50%, 0, 0);
    }
  
    60% {
      transform: translate3d(-50%, 200px, 0);
    }
  
    100% {
      transform: translate3d(-50%, 300px, 0);
    }
  }
    }
    @include g.mq-down(xl) {
      display: none;
    }




  }

}

.content_wrapper{
  width: 540px;
  margin: 0 auto;
  background-color: #fff;
  position: relative;
  z-index: 2;
  @include g.mq-down(md) {
    width: 100%;
  }
  header{
    width: 540px;
    height: auto;
      position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 100;
      
      @include g.mq-down(md) {
        width: 100%;
      }
    .nav_top {
      height: 70px;
      background-color: g.$pink;

    .box {
      position: relative;
      display: flex;


      .logo {
        max-width: 254px;
        width: 25%;
        height: auto;
        margin: 0 auto;
        display: flex;
        align-items: center;
        padding: 30px 0;
      }

      .menu {


        display: flex;
        align-items: center;
        justify-content: space-between;


        .menu-btn {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 1;
          cursor: pointer;

          .word {
            color: g.$text-color;
            font-size: 1rem;
            letter-spacing: 0;
            text-align: center;
            margin: 0;
            transition: margin-left .2s;



            &.active {
              margin-left: -3px;
            }
          }
        }
      }
    }
 
  }

  }
.navArea{
  width: 100%;
  height: calc( 100vh - 70px);
  position: absolute;
  left: 0;
  top: 70px;
  background: rgba($color: #ff858c, $alpha: 0.8);
  visibility: hidden;
  .inner{
    width: 90%;
    margin: 0 auto;
    .gNav{
      color: #fff;
      ul{
        padding-top: 40px;
        li{
          text-align: center;
          margin-bottom: 20px;
          font-size: 1.4em;
          font-weight: 600;
        }
      }
    }
    .cateArea{
      color: #fff;
      p{
        text-align: center;
        padding: 10px 0;
        border-top: dotted 2px #fff;
        border-bottom: dotted 2px #fff;
        font-size: 1.2em;

      }
      ul{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 30px auto;
        li{
          width: 48%;
          text-align: center;
          margin: 15px 0;
          font-size: 1.1em;
          font-weight: 600;
        }
      }
    }
    .lineArea{
      border-radius: 25px;
      .inner{
        padding: 40px 0;
      }
    }

    .closeBtn{
       width: 60%;
       margin: 20px auto;
      a{
        display: block;
        width: 100%;
        padding: 15px 0;
        font-size: 1.2em;
        font-weight: 600;
        color: #fff;
        border-radius: 30px;
        background-color: #57b86f;
        position: relative;
        text-align: center;
        .cross{
          width: 16px;
          position: absolute;
          right: 20px;
          top: calc(50% - 22px );
        }
      }
    }


  }
}

.top_bnr{
  width: 100%;
  background-color: #ff627e;
  ul{
    width: 90%;
    margin: 0 auto;
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li{
      width: 33%;
      text-align: center;
      margin: 15px auto;
      border-left: solid 1px #fff;
      &:nth-child(3n){
        border-right: solid 1px #fff;
      }
      a{
        color: #fff;
        font-weight: 600;
      }
    }

  }
  &.hide{
    display: none;
  }
}


}

