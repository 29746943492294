@use "../global" as g;

.p-single {
  .postArea {
    padding-top: percentage(80px / 750px);

    .inner {
      width: 90%;
      margin: 0 auto;

      .mv {
        margin-bottom: percentage(60px / 750px);

        figure {
          img {
            border-radius: min(20%, 10px);
            box-shadow: 10px 10px #FFD6D3;

            @include g.mq-down(md) {
              box-shadow: g.spvw(15) g.spvw(15) #FFD6D3;
            }
          }
        }
      }

      .title {
        font-size: 2.4rem;
        font-weight: 700;
        margin-bottom: percentage(40px / 750px);

        @include g.mq-down(md) {
          font-size: g.spvw(30);
        }
      }

      .data {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: percentage(15px / 750px);
        margin-bottom: percentage(30px / 750px);
        border-bottom: 2px dotted g.$pink;

        @include g.mq-down(md) {
          border-bottom: g.spvw(4) dotted g.$pink;
        }


        .day {
          color: g.$pink;
          font-weight: 700;
          font-size: 2rem;
          width: 50%;

          @include g.mq-down(md) {
            font-size: g.spvw(28);
          }
        }

        .cat {
          margin-top: percentage(-10px / 750px);
          width: 50%;
          text-align: right;

          span {
            display: inline-block;
            border: 2px solid g.$pink;
            border-radius: 9999px;
            font-size: 1.6rem;
            font-weight: 700;
            padding: 0 percentage(60px / 750px);
            color: g.$pink;

            @include g.mq-down(md) {
              font-size: g.spvw(24);
            }
          }
        }
      }

      .post {
        .block {
          margin-bottom: percentage(100px / 750px);
        }

        .midashi {
          font-size: 1.6rem;
          font-weight: 700;
          border-bottom: 2px dotted g.$pink;
          padding-bottom: percentage(20px / 750px);
          margin-bottom: percentage(40px / 750px);

          @include g.mq-down(md) {
            font-size: g.spvw(34);
          }
        }

        .textarea {
          font-size: 1.4rem;

          @include g.mq-down(md) {
            font-size: g.spvw(28);
          }
        }

        .editor {
          border: 1px solid #000;
          border-radius: 10px;
          padding: percentage(60px / 750px);
          box-shadow: 10px 10px #B9E1C3;

          @include g.mq-down(md) {
            box-shadow: g.spvw(15) g.spvw(15) #B9E1C3;
          }

          .box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10%;

            .photo {
              width: 162px;
              height: 181px;

              @include g.mq-down(md) {
                width: g.spvw(225);
                height: g.spvw(252);
              }

              figure {
                width: 100%;
                height: 100%;
                @include g.imgFit();
              }
            }

            .text {
              width: calc(100% - 180px);

              @include g.mq-down(md) {
                width: calc(100% - g.spvw(245));
              }

              .position {
                color: #57B86F;
                font-weight: 700;
                font-size: 1.4rem;
                border-bottom: 1px dotted #57B86F;
                padding-bottom: percentage(30px / 750px);
                margin-bottom: percentage(30px / 750px);

                @include g.mq-down(md) {
                  font-size: g.spvw(28);
                }
              }

              .name {
                font-size: 1.3rem;

                @include g.mq-down(md) {
                  font-size: g.spvw(28);
                }
              }
            }
          }

          .prof {
            font-size: 1.4rem;

            @include g.mq-down(md) {
              font-size: g.spvw(28);
            }

            .komidashi {
              text-align: center;
              font-weight: 700;
              margin-bottom: percentage(30px / 750px);
            }

            .read {
              text-align: justify;
            }
          }
        }

        .mokuji {
          #toc_container {
            background: #FFD6D3;
            border: 1px solid #000;
            border-radius: 10px;
            padding: percentage(60px / 750px);
            font-size: 1.4rem;

            @include g.mq-down(md) {
              font-size: g.spvw(28);
            }

            li {
              margin-bottom: percentage(30px / 750px);

              &:nth-last-of-type(1) {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  .articleArea {
    position: relative;

    .new_article {
      width: 100%;
      position: relative;
      z-index: 1;
    }

    .sec_ttl {
      font-size: 2.8rem;
      color: g.$pink-light;
      font-weight: 600;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: percentage(60px / 750px) 0 percentage(40px / 750px);
      margin-bottom: 30px;
      position: relative;

      .slash {
        width: percentage(45px / 750px);
        display: inline-block;
        margin: auto percentage(45px / 750px);
      }

      &::after {
        content: "";
        display: block;
        width: percentage(100px / 750px);
        height: percentage(80px / 750px);
        background: url(/assets/images/common/dot_p.png) no-repeat;
        background-size: 100%;
        position: absolute;
        bottom: 0px;

      }

      @include g.mq-down(md) {
        font-size: g.spvw(42);
      }
    }

    .article_list {
      width: 90%;
      margin: 0 auto 0px;

      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
          width: 47.5%;
          margin-bottom: 40px;

          .img {
            position: relative;
            width: 100%;
            box-sizing: border-box;

            z-index: 1;

            &::after {
              content: "";
              display: block;
              width: 100%;
              height: 100%;
              background-color: #ffd6d3;
              border-radius: 10px;
              position: absolute;
              right: -5px;
              bottom: -5px;
              z-index: -1;
            }

            figure {
              width: 100%;
              border: solid 1px g.$black;
              border-radius: 10px;
              overflow: hidden;

              img {
                width: 100%;
                object-fit: cover;

              }
            }

          }

          .caption {
            .dateArea {
              display: flex;
              justify-content: space-between;
              padding: 15px 0 10px;
              margin-bottom: 10px;
              border-bottom: dotted 2px #57b86f;
              font-size: 1.4rem;
              font-weight: 600;

              @include g.mq-down(md) {
                font-size: 2.5vw;
              }

              .date {
                color: g.$pink-light;
                font-size: 3vw;

              }

              .category {
                color: g.$pink-light;
                border: solid 1px g.$pink-light;
                border-radius: 20px;
                padding: 0 15px;
              }
            }

            .ttlArea {
              font-size: 2rem;
              font-weight: 700;
              line-height: 1.4;

              @include g.mq-down(md) {
                font-size: 3.5vw;
              }
            }
          }
        }
      }
    }


  }

  .note-sec {
    .inner {
      width: 90%;
      margin: 0 auto;
      font-size: 1.3rem;

      @include g.mq-down(md) {
        font-size: g.spvw(26);

        p {
          text-indent: -1em;
          margin-left: 1em;
        }
      }
    }
  }
}
